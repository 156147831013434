import { forwardRef, MutableRefObject, useEffect, useRef, useState } from "react";

import IconButton from "../Button/IconButton";

interface TextEditorProps {
  autofocus?: boolean;
  flavor?: "default" | "multiline";
  text: string;
  showEditIcon?: boolean;
  sanitize?: (newText: string) => string;
  onStopEditing: (newText: string) => void;
}

/**
 * An editable element (an input that looks like plain text) where a `<p>` is hidden behind the
 * input with identical text so that the input width can adjust like plain text (by default it
 * won't).
 *
 * Hitting escape will revert the changes.
 */
const TextEditor = forwardRef<HTMLInputElement, TextEditorProps>(
  ({ autofocus, flavor, text, sanitize = (x) => x, onStopEditing, showEditIcon = false }, ref) => {
    const [currentText, setCurrentText] = useState(text);
    const defaultRef = useRef<HTMLInputElement>();
    const inputRef = ref || defaultRef;

    const InputTag = flavor === "multiline" ? "textarea" : "input";

    useEffect(() => {
      const onKeyDown = (evt: KeyboardEvent): void => {
        switch (evt.key) {
          case "Escape":
            setCurrentText(text);

            window.requestAnimationFrame(() =>
              (inputRef as MutableRefObject<HTMLInputElement>).current.blur(),
            );

            return;
          case "Enter":
            return (inputRef as MutableRefObject<HTMLInputElement>).current.blur();
          default:
            return null;
        }
      };

      document.addEventListener("keydown", onKeyDown);

      return () => {
        document.removeEventListener("keydown", onKeyDown);
      };
    }, []);

    return (
      <span className="TextEditor">
        <span>
          <p>{currentText || "\xa0"}</p>
          <InputTag
            // there's not a great way to say, "this can be either a textarea element or an input
            // element" when dealing with the many different kinds of react refs. all we want is
            // something blurrable
            // @ts-ignore
            ref={inputRef}
            autoFocus={autofocus}
            onBlur={() => {
              const sanitizedText = sanitize(currentText.trim());

              if (!sanitizedText) {
                // revert; don't allow empties
                setCurrentText(text);
              } else if (sanitizedText !== currentText) {
                setCurrentText(sanitizedText);
              }

              onStopEditing(sanitizedText || text);
            }}
            onChange={(evt) => setCurrentText(evt.target.value)}
            type="text"
            value={currentText}
          />
        </span>

        {showEditIcon && (
          <IconButton
            icon="pencil"
            onClick={() => {
              (inputRef as MutableRefObject<HTMLInputElement>).current.focus();
            }}
          />
        )}
      </span>
    );
  },
);

export default TextEditor;
