import dayjs from "dayjs";
import { useRef } from "react";

import DatePicker from "@/components/DatePicker";
import HovercardToggle, { HovercardToggleHandle } from "@/components/HovercardToggle";
import SelectableList, { SelectableListItem } from "@/components/SelectableList";
import TextEditor from "@/components/TextEditor";

import { classnames } from "@/js/utils/cambio";
import { unitFormatter } from "@/js/utils/unitFormatter";

interface EditableCellProps {
  /** Current unedited rendered text */
  text: string;
  /** Only relevant for "enum" types */
  items?: SelectableListItem[];
  /** For the TextEditor. If the user types something we don't want, we can strip it out */
  sanitize?: (text: string) => string;
  /** Possible cell types, which governs how they are able to be edited */
  type?: "string" | "number" | "date" | "enum";
  /** Callback to finishing editing a cell */
  onEditCell: (text: number | string) => void;
  /** When passed, renders the unit as text next to the editable cell */
  unit?: string;
}

/**
 * Use this component in our Table cells to have them be editable based on their type. For numbers
 * and strings, we show an input field that appears as editable text. For a date, we should a
 * Calendar in a hovercard, and for an enum (where we want to constrain the values a user can
 * choose), we show a SelectableList in a hovercard.
 *
 * To edit a cell, you double-click in.
 */
export default function EditableCell({
  text,
  onEditCell,
  sanitize = (x) => x,
  items,
  unit,
  type = "string",
}: EditableCellProps) {
  const inputRef = useRef<HTMLInputElement>();
  const hovercardToggleRef = useRef<HovercardToggleHandle>();

  const onDoubleClick = () => {
    // this is for string/number types
    if (inputRef.current) {
      inputRef.current.focus();
    } else if (hovercardToggleRef.current) {
      hovercardToggleRef.current.open();
    }
  };

  return (
    <span
      className={classnames("EditableCell", { numeric: type === "number" })}
      onDoubleClick={onDoubleClick}
      onBlur={() => {
        // the defer is to ensure the document.activeElement has been updated if we click a button
        // on a hovercard. Otherwise it will just be document.body
        window.setTimeout(() => {
          if (!hovercardToggleRef.current?.getHovercardNode()?.contains(document.activeElement)) {
            hovercardToggleRef.current?.close();
          }
        }, 0);
      }}
      // make this element focusable/blurrable, which adds the green border
      tabIndex={0}
    >
      {(() => {
        switch (type) {
          case "enum":
            return (
              <HovercardToggle
                disabled
                ref={hovercardToggleRef}
                contents={() => (
                  <SelectableList items={items} onClick={(item) => onEditCell(item.key)} />
                )}
              >
                <span>{text}</span>
              </HovercardToggle>
            );
          case "date":
            return (
              <DatePicker
                ref={inputRef}
                onChange={(date) => onEditCell(date ? dayjs(date).toISOString() : null)}
                value={text}
              />
            );
          case "string":
          case "number":
          default:
            return (
              <>
                <TextEditor
                  ref={inputRef}
                  sanitize={(newText) => sanitize(sanitizeInput(newText, type))}
                  key={text}
                  text={text}
                  onStopEditing={(newText) =>
                    onEditCell(type === "number" ? window.parseFloat(newText) : newText)
                  }
                />
                {unit ?
                  <span className="unit">{unitFormatter(unit)}</span>
                : null}
              </>
            );
        }
      })()}
    </span>
  );
}

/**
 * Corrects any input based on the type, which is especially important for input fields where the
 * user can type in anything.
 */
export function sanitizeInput(text: string, type: "string" | "number"): string {
  switch (type) {
    case "number":
      // after this we know that we can safely convert to a float
      return "" + (window.parseFloat(text.replace(/[^0-9.]/g, "")) || 0);
    case "string":
      return text.trim();
    default:
      return text;
  }
}
